<script>
import {defineComponent} from 'vue'
import Step1 from './Step1.vue'
import Step2 from './Step2.vue'
import Step3 from './Step3.vue'
import Step4 from './Step4.vue'
import Step5 from './Step5.vue'
import AutoComplete from './AutoComplete.vue'
import {emptyWagonOrderComputed, emptyWagonOrdersMethods} from "@/state/helpers"
import Swal from "sweetalert2";

export default defineComponent({
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from) {
        vm.$store.state.emptyWagonOrder.pre_order = from.name === "pre_orders_list" ? vm.$store.state.emptyWagonOrder.pre_order : {
          id: '',
          type: ''
        }
      } else {
        vm.$store.state.emptyWagonOrder.pre_order = {id: '', type: ''}
      }
    })
  },
  name: "create",
  emits: ['created'],
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    AutoComplete
  },
  props: {
    perform: {
      type: String,
      required: false,
      default: 'create'
    }
  },
  data() {
    return {
      current_step: 1,
    }
  },
  methods: {
    ...emptyWagonOrdersMethods,
    goStep(step) {
      this.current_step = step
    },
  },
  computed: {
    ...emptyWagonOrderComputed,
    counterparties: {
      get() {
        return this.$store ? this.$store.state.emptyWagonOrder.counterparties : []
      },
      set(value) {
        this.addCounterparty(value)
      }
    },
    request_status: {
      get() {
        return this.$store ? this.$store.state.emptyWagonOrder.request_status : ''
      },
      set(value) {
        this.setRequestStatus(value)
      }
    },
    is_pre_order: {
      get() {
        return this.$store
            ? this.$store.state.emptyWagonOrder.pre_order && this.$store.state.emptyWagonOrder.pre_order.type === 'code_order'
            : false
      },
      set(value) {
        this.setIsPreOrder(value)
      }
    },
    pre_order: {
      get() {
        return this.$store ? this.$store.state.emptyWagonOrder.pre_order : {}
      },
      set(value) {
        this.setPreOrder(value)
      }
    },
  },
  mounted() {
    if (this.request_status === 'success') {
      this.fullClean()
    }
    if (this.counterparties.length > 0) return
    if (this.pre_order && this.pre_order.type === 'code_order') {
      this.addCounterparty({
        delete_is_confirmed: false,
        counterparty: {id: 1, label: '255'},
        categories: [],
        territories: []
      })
    } else {
      this.addCounterparty({
        delete_is_confirmed: false,
        counterparty: {id: 1, label: '255'},
        categories: [],
      })
    }
  },
  watch: {
    current_step(newVal, oldVal) {
      let perStep = parseInt(oldVal)
      let nextStep = parseInt(newVal)

      if (nextStep < perStep) return

      for (let i = perStep; i < nextStep; i++) {
        if (this.$refs[`Step${i}`].goNextStep()) {
          this.current_step = i + 1
        } else {
          this.current_step = i
          return
        }
      }
    },
    is_pre_order(newVal) {
      if (newVal) {
        Swal.fire({
          title: 'Welcome to Pre-Order',
          html: '<span class="text-muted">' +
              'We have filled some fields based on your pre-order.' +
              ' Please check them and make sure they are correct.</span>',
        })
      }
    }
  }
})
</script>

<template>

  <b-row>
    <template v-if="request_status === 'initial'">
      <b-col xl="9">
        <b-card no-body>
          <b-card-body>
            <b-form>

              <div class="step-arrow-nav mt-n3 mx-n3 mb-3 rounded-3">

                <ul class="nav nav-pills nav-justified custom-nav rounded-3 overflow-hidden" role="tablist">
                  <li class="nav-item bg-soft-success border-end" role="presentation">
                    <div class="nav-link cursor-pointer p-3" @click="goStep(1)"
                         :class="{ 'active': current_step === 1,
                                    'done': current_step > 1 }">
                      <h5>
                        <span class="badge badge-gradient-info mt-1">Order Info 1</span>
                      </h5>
                    </div>
                  </li>
                  <li class="nav-item bg-soft-success border-end" role="presentation">
                    <div class="nav-link cursor-pointer p-3" @click="goStep(2)"
                         :class="{ 'active': current_step === 2,
                                    'done': current_step > 2 }">
                      <h5>
                        <span class="badge badge-gradient-info mt-1">Order Info 2</span>
                      </h5>
                    </div>
                  </li>
                  <li v-if="perform === 'create'" class="nav-item bg-soft-success border-end" role="presentation">
                    <div class="nav-link cursor-pointer p-3" @click="goStep(3)"
                         :class="{ 'active': current_step === 3,
                                    'done': current_step > 3 }">
                      <h5>
                        <span class="badge badge-gradient-info mt-1">Counterparty Info</span>
                      </h5>
                    </div>
                  </li>
                  <li v-if="perform === 'create'"  class="nav-item bg-soft-success" role="presentation">
                    <div class="nav-link cursor-pointer p-3" @click="goStep(4)"
                         :class="{ 'active': current_step === 4,
                                    'done': current_step > 4 }">
                      <h5>
                        <span class="badge badge-gradient-info mt-1">Counterparty Prices</span>
                      </h5>
                    </div>
                  </li>
                </ul>

              </div>

              <form class="tab-content">

                <div class="tab-pane fade" :class="{'show active': current_step === 1}">
                  <Step1 :perform="perform" ref="Step1"
                         @go-next-step="current_step = 2"
                  />
                </div>

                <div class="tab-pane fade" :class="{'show active': current_step === 2}">
                  <Step2 :perform="perform" ref="Step2"
                         @go-next-step="current_step = 3"
                         @go-previous-step="current_step = 1"
                  />
                </div>

                <div class="tab-pane fade" :class="{'show active': current_step === 3}">
                  <Step3 ref="Step3"
                         @go-next-step="current_step = 4"
                         @go-previous-step="current_step = 2"
                  />
                </div>

                <div class="tab-pane fade" :class="{'show active': current_step === 4}">
                  <Step4 ref="Step4"
                         @go-next-step="current_step = 5"
                         @go-previous-step="current_step = 3"
                         @created="this.$emit('created')"
                  />
                </div>

              </form>

            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="3">
        <b-alert :show="is_pre_order" variant="secondary" class="alert-solid" role="alert">
          <strong>Pre Order!</strong> - <b>You are filling a pre order</b>
        </b-alert>
        <b-alert :show="perform === 'update'" variant="secondary" class="alert-solid" role="alert">
          <strong>Order: {{ $route.params.id }}</strong> - You're updating <b> EMPTY WAGON </b> order
        </b-alert>
        <AutoComplete v-if="perform === 'create'"/>
      </b-col>
    </template>

    <template v-else>
      <b-col sm="12">
        <b-card no-body>
          <b-card-body>
            <Step5></Step5>
          </b-card-body>
        </b-card>
      </b-col>
    </template>

  </b-row>

</template>

<style scoped>

</style>