<script>
import {defineComponent} from 'vue'
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import {emptyWagonOrderComputed, emptyWagonOrdersMethods} from "@/state/helpers";
import Swal from "sweetalert2";

export default defineComponent({
  name: "SelectShipmentProduct",
  components: {
    Multiselect,
  },
  data() {
    return {
      products: [],
      selected_product: null,
    }
  },
  computed: {
    ...emptyWagonOrderComputed,
  },
  methods: {
    ...emptyWagonOrdersMethods,
    async getShipmentProducts() {
      try {
        let response = await axios.get('/counterparty/product/list/')
        this.products = response.data.results.map((product) => {
          return {
            value: product.id,
            label: product.name + ' (' + product.container_type + ')',
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getSelectedProductDetails(product_id) {
      try {
        let response = await axios.get(`/counterparty/product/${product_id}/`)
        return response.data
      } catch {
        return []
      }
    },
    async onShipmentProductSelected(event) {
      if (!event || this.selected_product === event) return
      let details = await this.getSelectedProductDetails(event)
      let counterparties = details.shipment_details

      if (counterparties.length === 0) {
        await Swal.fire({
          title: 'No counterparties found \nfor this selected product',
          html: 'Please add a counterparty first <br> and try again later',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
        return
      }
      this.$store.state.emptyWagonOrder.counterparties = []

      counterparties.forEach((counterparty) => {
        let party = {...counterparty}
        this.addCounterparty({
          counterparty: {
            value: party.counterparty_id,
            label: party.counterparty_name,
          },
          categories: [...party.categories].map(category => {
            let cat = {...category}
            return {
              value: cat.category_id,
              label: cat.category_name,
              price: cat.price,
            }
          }),
          delete_is_confirmed: false,
        })
      })
    }
  },
  created() {
    this.getShipmentProducts()
  }
})
</script>

<template>
  <Multiselect
      placeholder="Use shipment product"
      @input="onShipmentProductSelected"
      :options="products" :searchable="true"
      v-model="selected_product"/>
</template>

<style scoped>

</style>